<template>
  <v-container 
    style="
      padding: 0;
      margin-top: 122px;
    "
    fluid
  >
    <status-bar :showCompletedButton="showCompletedButton"></status-bar>

    <v-container>
      <v-row>
        <v-card class="mx-5">
          <v-card-title>
            <h1 class="display-1">
              Getting Started
            </h1>
          </v-card-title>
          <v-card-text>

            <div class="px-5 pb-7">
              This is the place to provide all the information to generate agreements for your customers.
            </div>
            <v-form ref="form">
              <v-card>
                <v-card-title>Company Name</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="companyName"
                    prepend-icon="mdi-account-circle"
                    label="Company Name"
                    title="Your Company Name"
                    :rules="companyNameRules"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  Upload Logo
                </v-card-title>
                <v-card-text>
                  <v-row style="margin-bottom: 23px">
                    <v-col cols="8">
                      <v-form>
                        <v-file-input
                          label="File input"
                          v-model="logo"
                          filled
                          :rules="logoRules"
                          required
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-form>
                    </v-col>
                    <v-col cols="4">
                      <v-img
                        max-height="80"
                        contain
                        :src="logo"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>Address</v-card-title>
                <v-card-text>
                  <v-text-field
                    class="mt-5"
                    label="Street"
                    v-model="address.street"
                    :rules="addressRules"
                    required
                  ></v-text-field>
                  
                  <v-text-field
                    class="mt-5"
                    label="City"
                    v-model="address.city"
                    :rules="addressRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    class="mt-5"
                    label="State"
                    v-model="address.state"
                    :rules="addressRules"
                    required
                  ></v-text-field>

                  <v-text-field
                    class="mt-5"
                    label="Zip Code"
                    v-model="address.zip"
                    :rules="addressRules"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>Name of Service</v-card-title>
                <v-card-text>
                  This is the general name of the basic service that you are delivering throughout the year 
                  (lawn mowing, haircuts, HVAC airconditioner and heater services, trumpet lessons, counseling 
                  sessions, natural gas delivery, irrigation system turn on/off).
                  <v-text-field
                    class="mt-5"
                    v-model="serviceName"
                    prepend-icon="mdi-account-circle"
                    label="Name of Service"
                    :rules="serviceNameRules"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>Service Description</v-card-title>
                <v-card-text>
                  This is a detailed description of the services being provided for the level fee price. multiple service provided or services not included.  This information will be shown on the service agreement.
                  <v-textarea
                    class="mt-5"
                    label="Service Description"
                    v-model="serviceDescription"
                    :rules="serviceDescriptionRules"
                    required
                  ></v-textarea>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>Monthly Billing Date</v-card-title>
                <v-card-text>
                  Enter the date that credit payments will be processed (1-28)
                  <v-text-field
                    v-model="billDate"
                    single-line
                    type="number"
                    :rules="billDateRules"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  Phone
                </v-card-title>
                <v-card-text>
                  Your business phone number.
                  <v-text-field
                    v-model="businessPhone"
                    :rules="phoneRules"
                    label="10 digit number, no spaces, no non-digits"
                    type="number"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card>

              <v-card>
                <v-card-title>
                  Email
                </v-card-title>
                <v-card-text>
                  Email for customers to contact.
                  <v-text-field
                    v-model="businessEmail"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-card-text>
              </v-card>

            </v-form>
          </v-card-text>
          <v-row>
            <v-col>
              <v-card-actions>
                <v-row>
                  <v-col>
                    <v-btn @click="submitSLPData">Create Service Level Pay</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-row>
    </v-container>

    <Footer></Footer>

  </v-container>
</template>

<script>
import { DB } from '../firebase/db'
import { Storage } from '../firebase/storage'

import { mapState } from 'vuex'
import router from '../router'

import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'GetStarted',
  components: {
    StatusBar,
    Footer,
  },
  data() {
    return {
      logo: undefined,
      showCompletedButton: false,
      logoRules: [
        v => !!v || "Logo is required",
      ],
      companyName: '',
      companyNameRules: [
        v => !!v || "Company Name is required",
      ],
      serviceName: '',
      serviceNameRules: [
        v => !!v || "Service Name is required",
      ],
      address: {
        street: '',
        city: '',
        state: '',
        zip: '',
      },
      addressRules: [
        v => !!v || "Address is required",
      ],
      serviceDescription: '',
      serviceDescriptionRules: [
        v => !!v || "Service Description is required",
        v => (v || '' ).length <= 570 || 'Description must be 570 characters or less'
      ],
      phoneRules: [
        v => !!v || "Phone is required",
        v => /[0-9]{10}/.test(v) || 'Phone must be a 10 digit number, no spaces, no non-digits',
      ],
      billDate: undefined,
      billDateRules: [
        v => !!v || "Bill Date is required",
        v => ( v && v >= 1 ) || "Cannot be less than 1",
        v => ( v && v <= 28 ) || "Must be less than 29",
      ],
      businessPhone: undefined,
      businessEmail: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      logoURL: undefined,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    getSLPData() {
      // Get SLP Data here
      let customerAccountRef = DB.collection("user").doc(this.user.uid)

      customerAccountRef
        .get()
        .then((user) => {
          user = user.data()
          if(user.slpAgreement != undefined) {
            this.showCompletedButton = true
          }

          let slpData = user.slpAgreement

          this.logo = slpData.logoURL
          this.companyName = slpData.companyName
          this.serviceName = slpData.serviceName
          this.serviceDescription = slpData.serviceDescription
          this.billDate = slpData.billDate
          this.businessPhone = slpData.businessPhone
          this.businessEmail = slpData.businessEmail
          this.address.street = slpData.address.street
          this.address.city = slpData.address.city
          this.address.state = slpData.address.state
          this.address.zip = slpData.address.zip
        }).catch((err) => {
          console.log(err)
        })
    },
    submitSLPData() {
      // console.log(' \nthis.logo: '+this.logo +' \nthis.companyName: '+ this.companyName +' \nthis.serviceName: '+ this.serviceName +' \nthis.serviceDescription: '
      // + this.serviceDescription + ' \nthis.percentDiscount: '+ this.percentDiscount +' \nthis.regularPriceForLevelPayService: '
      // + this.regularPriceForLevelPayService +' \nthis.billDate: '+ this.billDate +' \nthis.businessPhone: '+ this.businessPhone +' \nthis.businessEmail: '
      // + this.businessEmail + ' \nthis.logoURL: '+ this.logoURL +' \nthis.address: '+ this.address.street +
      // ' \nthis.address: '+ this.address.city +' \nthis.address: '+ this.address.state +' \nthis.address: '+ this.address.zip)
      this.$refs.form.validate();
      if (this.logo && 
        this.companyName && 
        this.serviceName && 
        this.serviceDescription && 
        this.billDate && 
        this.businessPhone && 
        this.businessEmail && 
        this.address.street && 
        this.address.city && 
        this.address.state && 
        this.address.zip) {
        let storageRef = Storage.ref(this.user.uid+'/companyLogo/'+this.logo.name)
        storageRef.put(this.logo)
          .then(() => {
            storageRef.getDownloadURL()
              .then((downloadURL) => {
                this.logoURL = downloadURL

                let customerAccountRef = DB.collection("user").doc(this.user.uid)

                this.showCompletedButton = true

                customerAccountRef
                  .set( {
                    slpAgreement: {
                      companyName: this.companyName,
                      serviceName: this.serviceName,
                      serviceDescription: this.serviceDescription,
                      billDate: this.billDate,
                      businessPhone: this.businessPhone,
                      businessEmail: this.businessEmail,
                      logoURL: this.logoURL,
                      address: this.address,
                    }
                  }, { merge: true })
                  .then(() => {
                    console.log("Document successfully updated!")
                    router.push({ name: 'Home' })
                  })
              })
          })
          .catch((err) => {
            alert(err)
          }) 
      } else {
        alert('Missing information')
      } 
    },
  },
  mounted() {
    this.getSLPData()
  }
}
</script>
